import React from 'react';
import { Link } from 'theme-ui';
import RouterLink from 'next/link';

function AnchorLink({ children, sx, href, ...rest }) {
  return (
    <RouterLink href={href} passHref>
      <Link
        sx={{
          fontWeight: 'bold',
          color: 'white',
          textDecoration: 'none',
          ...sx,
        }}
        {...rest}
      >
        {children}
      </Link>
    </RouterLink>
  );
}

export default AnchorLink;
