import React from 'react';
import { Flex } from 'theme-ui';

const Section = ({ sx, children }) => (
  <Flex
    as="section"
    sx={(theme) => ({
      maxWidth: theme.breakpoints[theme.breakpoints.length - 1],
      flexDirection: 'column',
      mx: 'auto',
      px: 3,
      ...sx,
    })}
  >
    {children}
  </Flex>
);

export default Section;
