import { Box, Flex, Link } from 'theme-ui';
import React, { useState, useEffect } from 'react';
import NextLink from 'next/link';
import Image from 'next/image';

import mask from 'images/banner.svg';
import logo from 'images/logo.svg';
import { useTranslation } from 'react-i18next';
import SetupLink from 'components/billing/setuplink';

const Header = ({ setPlayHero }) => {
  const { t } = useTranslation();
  const [scroll, setScroll] = useState(0);
  const [width, setWidth] = useState(1920);

  const handleScroll = () => {
    const scrollAmount =
      document.body.scrollTop || document.documentElement.scrollTop;
    setScroll(scrollAmount);
    setWidth(document.documentElement.clientWidth);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    setWidth(document.documentElement.clientWidth);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const bgTranslate = Math.max(
    Math.floor(-250 - scroll * 0.2 - (width - 1600) / 5),
    -500
  );

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          overflow: 'hidden',
          height: 500,
          maskImage: `url(${mask})`,
          maskPosition: '-620px bottom',
        }}
      >
        <Box
          sx={{
            transform: [
              'scale(2) translate(0%, 20%)',
              `translate(0, ${bgTranslate}px)`,
            ],
            backgroundColor: 'brand',
          }}
        >
          <Image
            src="/landing/studio.jpg"
            layout="responsive"
            width={4096}
            height={2731}
            priority
            loading="eager"
            alt=""
            objectFit="cover"
            onLoad={() => setPlayHero(true)}
          />
        </Box>
      </Box>

      <Flex
        as="nav"
        sx={{
          height: 50,
          alignItems: 'center',
          position: 'fixed',
          zIndex: 10,
          width: '100%',
          backgroundColor: ['dark', scroll > 450 ? 'dark' : 'transparent'],
          transition: 'background-color 300ms ease-in-out',
        }}
      >
        <NextLink href="/" passHref>
          <Link marginLeft={[2, 3]} marginRight={[0, 3]}>
            <Image src={logo} alt="fotokorner" height="30" width="131" />
          </Link>
        </NextLink>
        <NextLink href="/login" prefetch={false} passHref>
          <Link
            sx={{
              borderWidth: [0, 1],
              fontSize: 0,
              fontWeight: 'light',
              borderColor: 'white',
              borderRadius: [0, 40],
              borderStyle: 'solid',
              paddingY: [0, 1],
              paddingX: [1, 3],
              color: 'white',
              fontFamily: 'heading',
              marginLeft: 'auto',
              textDecoration: 'none',
              textTransform: 'lowercase',
            }}
          >
            {t('landing.header.login')}
          </Link>
        </NextLink>
        <Flex
          sx={{
            backgroundColor: 'brand',
            height: '100%',
            alignItems: 'center',
            paddingX: [2, 3],
            marginLeft: [2, 3],
          }}
        >
          <SetupLink as={NextLink} href="/register" prefetch={false} passHref>
            <Link
              sx={{
                marginY: 'auto',
                color: 'white',
                textDecoration: 'none',
                fontSize: [1, 2],
                fontFamily: 'heading',
                textTransform: 'lowercase',
                fontWeight: 'bold',
              }}
            >
              {t('landing.header.get_started')}
            </Link>
          </SetupLink>
        </Flex>
      </Flex>
    </>
  );
};

export default Header;
