import { Global } from '@emotion/react';

const montserratRegular = {
  '@font-face': {
    fontFamily: 'Montserrat',
    src: `url(/fonts/Montserrat-Regular.woff2) format('woff2'),
    url(/fonts/Montserrat-Regular.woff) format('woff'),
    url(/fonts/Montserrat-Regular.ttf) format('truetype')`,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
};

const montserratLight = {
  '@font-face': {
    fontFamily: 'Montserrat',
    src: `url(/fonts/Montserrat-Light.woff2) format('woff2'),
    url(/fonts/Montserrat-Light.woff) format('woff'),
    url(/fonts/Montserrat-Light.ttf) format('truetype')`,
    fontWeight: 300,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
};

const montserratMedium = {
  '@font-face': {
    fontFamily: 'Montserrat',
    src: `url(/fonts/Montserrat-Medium.woff2) format('woff2'),
    url(/fonts/Montserrat-Medium.woff) format('woff'),
    url(/fonts/Montserrat-Medium.ttf) format('truetype')`,
    fontWeight: 500,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
};

const coolveticaRgRegular = {
  '@font-face': {
    fontFamily: 'Coolvetica Rg',
    src: `url(/fonts/CoolveticaRg-Regular.woff2) format('woff2'),
    url(/fonts/CoolveticaRg-Regular.woff) format('woff'),
    url(/fonts/CoolveticaRg-Regular.ttf) format('truetype')`,
    fontWeight: 500,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
};

export default function Fonts() {
  return (
    <Global
      styles={[
        montserratRegular,
        montserratLight,
        montserratMedium,
        coolveticaRgRegular,
      ]}
    />
  );
}
