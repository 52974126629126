import React from 'react';
import { Flex, Link, Box } from 'theme-ui';
import NextImage from 'next/image';
import { useTranslation } from 'react-i18next';
import NextLink from 'next/link';

import facebook from 'images/facebook.svg';
import instagram from 'images/instagram.svg';
import twitter from 'images/twitter.svg';
import logo from 'images/logo.svg';

const SocialLink = ({ href, title, src, alt }) => (
  <Link
    href={href}
    title={title}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Box
      sx={{
        height: '100%',
        padding: [2, 1],
      }}
    >
      <NextImage src={src} alt={alt} />
    </Box>
  </Link>
);

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Flex
      as="footer"
      sx={{
        flexDirection: ['column', 'row'],
        alignItems: ['flex-start', 'center'],
        color: 'light',
        fontSize: 1,
        fontFamily: 'heading',
        backgroundColor: 'dark',
        paddingX: 2,
        paddingY: 2,
      }}
    >
      <Flex
        sx={{
          height: [48, 24],
          alignItems: 'center',
          width: ['100%', 'auto'],
          marginRight: [0, 'auto'],
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '6em',
            paddingY: [3, 1],
            marginRight: ['auto', 2],
          }}
        >
          <NextImage src={logo} alt="Fotokorner" layout="responsive" />
        </Box>

        <SocialLink
          href="https://www.facebook.com/fotokornerapp/"
          title={t('landing.footer.follow_facebook')}
          src={facebook}
          alt="Facebook"
        />

        <SocialLink
          href="https://www.instagram.com/fotokornerapp/"
          title={t('landing.footer.follow_instagram')}
          src={instagram}
          alt="Instagram"
        />

        <SocialLink
          href="https://twitter.com/fotokorner/"
          title={t('landing.footer.follow_twitter')}
          src={twitter}
          alt="Twitter"
        />
      </Flex>

      <Box
        sx={{
          marginTop: [2, 0],
          paddingRight: [0, 1],
          marginRight: [0, 1],
          borderRight: [0, '2px solid white'],
        }}
      >
        <Link href="mailto:hello@fotokorner.com" variant="footer">
          {t('landing.footer.contact_us')}
        </Link>
      </Box>
      <Box
        sx={{
          marginTop: [2, 0],
        }}
      >
        <NextLink href="/privacy" passHref>
          <Link variant="footer">{t('landing.footer.privacy_policy')}</Link>
        </NextLink>
      </Box>
    </Flex>
  );
};

export default Footer;
