import React from 'react';
import { ThemeProvider } from 'theme-ui';
import { Global } from '@emotion/react';
import { Box } from 'theme-ui';

import Header from './header';
import Footer from './footer';
import theme from '../../themelanding';
import Crisp from 'components/crisp';
import Fonts from 'components/fonts';

const Layout = ({ children, setPlayHero }) => {
  return (
    <ThemeProvider theme={theme}>
      <Crisp />
      <Box
        sx={{
          fontFamily: 'body',
        }}
      >
        <Header siteTitle="fotokorner" setPlayHero={setPlayHero} />

        <Box
          sx={{
            minHeight: 'calc(100vh - 75px)',
            position: 'relative',
            zIndex: 2,
          }}
        >
          {children}
        </Box>

        <Footer />
      </Box>
      <Fonts />
      <Global
        styles={(theme) => ({
          body: {
            backgroundColor: theme.colors.background,
            margin: 0,
            padding: 0,
          },
        })}
      />
    </ThemeProvider>
  );
};

export default Layout;
