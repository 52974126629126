import { useRouter } from 'next/router';

import AnchorLink from 'components/anchorlink';

export const getQs = (query) => {
  const params = [];
  if (query.ref) {
    params.push(`ref=${query.ref}`);
  }
  if (query.currency) {
    params.push(`currency=${query.currency}`);
  }
  return params.length > 0 ? '?' + params.join('&') : '';
};

// A link that automatically persists ref and currency during the setup tunnel
export default function SetupLink({
  href,
  children,
  as = AnchorLink,
  ...rest
}) {
  const { query } = useRouter();
  const Link = as;

  const newQuery = {};
  if (query.ref) {
    newQuery.ref = query.ref;
  }
  if (query.currency) {
    newQuery.currency = query.currency;
  }

  return (
    <Link href={{ pathname: href, query }} {...rest}>
      {children}
    </Link>
  );
}
