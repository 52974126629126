import React from 'react';
import { useTranslation } from 'react-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';

function SEO({ description, meta = [], title }) {
  const { t } = useTranslation();
  const { locales, asPath } = useRouter();

  const metaDescription = description || t('landing.meta.description');
  const formattedTitle = title
    ? `fotokorner :: ${title}`
    : `fotokorner :: ${t('landing.meta.description')}`;

  const metas = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: formattedTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: `https://fotokorner.com/ogimage.jpg`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: '@fotokorner',
    },
    {
      name: `twitter:title`,
      content: formattedTitle,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    ...meta,
  ];

  return (
    <Head>
      <title>{formattedTitle}</title>
      {metas.map((meta) => (
        <meta key={meta.name || meta.property} {...meta} />
      ))}
      {locales.map((locale) => (
        <link
          rel="alternate"
          key={locale}
          hrefLang={locale}
          href={`${process.env.NEXT_PUBLIC_DOMAIN}/${locale}${
            asPath === '/' ? '' : asPath
          }`}
        />
      ))}
    </Head>
  );
}

export default SEO;
