import React from 'react';
import Script from 'next/script';

export default function Crisp() {
  return (
    <Script id="crisp-chat" strategy="lazyOnload">
      {`window.$crisp=[];window.CRISP_WEBSITE_ID="03542188-d049-469b-968d-61ca547b1e4b";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
    </Script>
  );
}
